<template>
  <div class="company-info">
    <!-- 顶部图片区域 -->
    <div class="image-header"></div>
    <img class="logo-image" src="../assets/logo.png" alt="Logo" />
    <!-- 基本信息图标和标题 -->
    <div class="basic-info-header">
      <i class="el-icon-s-promotion"></i>
      <span>请填写您的宝贵意见</span>
    </div>

    <!-- 表单部分 -->
    <div class="formbox">
      <div class="form-container">
        <el-form ref="companyForm" :model="companyForm" :rules="rules" label-width="0px" label-position="top">
          <div style="padding: 0px 20px 20px">
            <!-- 姓名 -->
            <el-form-item label="您的姓名" prop="contactName">
              <template slot="label">
                <img src="../assets/iocn1.png" alt="icon" class="icon" />
                您的姓名
              </template>
              <el-input v-model="companyForm.contactName" placeholder="此项非必填项，可匿名发送"></el-input>
            </el-form-item>

            <!-- 联系电话 -->
            <el-form-item label="联系电话" prop="contactInformation">
              <template slot="label">
                <img src="../assets/icon2.png" alt="icon" class="icon" />
                联系电话
              </template>
              <el-input v-model="companyForm.contactInformation" placeholder="此项非必填项，可匿名发送"></el-input>
            </el-form-item>

            <!-- 意见 -->
            <el-form-item label="建议/意见" prop="emailContent">
              <template slot="label">
                <img src="../assets/icon3.png" alt="icon" class="icon" />
                建议/意见
              </template>
              <el-input type="textarea" :rows="5" v-model="companyForm.emailContent"></el-input>
            </el-form-item>
          </div>

          <!-- 提交按钮 -->
          <div style="display: flex; justify-content: center">
            <el-button class="submit-button" type="primary" @click="handleSubmit">提交</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index">冀ICP备2020026660号</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      companyForm: {
        contactName: '',
        contactInformation: '',
        emailContent: '',
      },
      rules: {
        // 表单校验规则
        contactName: [{ required: false, message: '请输入姓名', trigger: 'blur' }], // 姓名非必填
        contactInformation: [{ required: false, message: '请输入联系电话', trigger: 'blur' }], // 联系电话非必填
        emailContent: [{ required: true, message: '请填写意见', trigger: 'blur' }], // 意见字段设置为必填项
      },
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.companyForm.validate((valid) => {
        if (valid) {
          axios.post('/dream-api/president-mailbox/add', this.companyForm).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message)
              this.companyForm = {
                contactName: '',
                contactInformation: '',
                emailContent: '',
              }
            } else {
              this.$message.error(res.data.message)
            }
          })
        }
      })
    },
  },
}
</script>

<style>
.icon {
  width: 16px; /* 图标宽度 */
  height: 16px; /* 图标高度 */
  margin-right: 5px; /* 图标和文字之间的间距 */
  margin-bottom: 2px;
  vertical-align: middle; /* 图标和文字居中对齐 */
}
.el-form-item__label {
  color: #333 !important;
  font-weight: bold;
}
.company-info {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo-image {
  position: absolute;
  top: 10px; /* 距离顶部10px */
  left: 10px; /* 距离左边10px */
  width: 80px; /* logo宽度 */
  height: auto; /* 保持图片比例 */
  z-index: 10; /* 确保logo在最上面 */
}

.submit-button {
  background-color: #fc8200 !important;
  border-color: #fc8200 !important;
  border-radius: 5px !important;
  width: calc(100% - 40px);
  margin-bottom: 20px !important;
}

.image-header {
  width: 100%;
  background: url(../assets/top.jpg);
  height: 212px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.basic-info-header {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 16px;
  color: #c0aa91;
  font-weight: bold;
}

.basic-info-header i {
  font-size: 24px;
  margin-right: 10px;
}

.form-container {
  background: url(../assets/home.png);
  background-size: 100% 60%;
  background-repeat: no-repeat;
}
.formbox {
  height: calc(100vh - 212px - 54px);
  background: linear-gradient(180deg, rgba(146, 121, 88, 0.83) 0%, #fffbfb 100%);
  border-radius: 6px;
}

.el-form-item {
  margin-bottom: 20px;
}

.footer {
  text-align: center;
  padding: 10px 0;
  font-size: 12px;
  color: #999;
  background-color: #f9f9f9;
}
</style>
